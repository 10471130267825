import React from "react"
import Layout from "../components/layout"
import SmoothScroll from "../components/smooth-scroll"
import AboutContent from "../components/about-content"

const About = ({}) => {
  return (
    <Layout>
      <AboutContent />
    </Layout>
  )
}

export default About
