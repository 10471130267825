import React from "react"
import { AboutText } from "../styles/about-styles"
import { Container, easeInOut } from "../styles/global-styles"
import { WorkContentGrid } from "../styles/work-styles"

const WorkContent = () => {
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={easeInOut(0.3)}
    >
      <WorkContentGrid>
        <AboutText>
          Hi, there 👋🏾 <br /> <br />
          My name is Alvin Ghouas. <br /> I am a full-stack software engineer
          and UX designer. I do most of my work in JavaScript, Python and Adobe
          XD. Outside of work I enjoy running 🏃🏾‍♂️, reading 📚 and climbing 🧗🏾‍♂️
          <br /> <br /> If you wanna chat about code, design or just life, feel
          free to reach me on alvin.ghouas@gmail.com{" "}
        </AboutText>
      </WorkContentGrid>
    </Container>
  )
}

export default WorkContent
