import styled from "styled-components"
import { device } from "./device"
export const AboutText = styled.p`
  font-size: 1.5rem;
  line-height: 3rem;
  margin: 0;
  grid-column-end: span 4;

  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 6rem;
    grid-column-end: span 11;
  }
`
export const AboutContentGrid = styled.div`
  display: grid;
  width: 320px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  column-gap: 0.5rem;
  margin-bottom: 10rem;
  margin-top: 8rem;
  position: relative;

  @media ${device.tablet} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: max-content;
    width: 100%;
    margin-bottom: 14rem;
  }
`
